/**
 * @author       [Stef Coenen]
 * @link         [http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html]
 * @namespace    [Dom.fn]
 * @requires     [jQuery, Dom]
 */

// @param ($): jquery library 1.11.1
// @param (ns): window.Dom
window.Syte = (function($, ns) {
  // 1. ECMA-262/5
  "use strict";

  // 2. CONFIGURATION
  var cfg = {};

  var apiTokenUrl = "http://vcvoervallei.local/wp-json/volley_api/token";

  // 3. FUNCTIONS OBJECT
  ns.fn = {
    /**
     * @description Render html template with json data
     * @see handlebars or mustache if you need more advanced functionlity
     * @param {Object} obj
     * @param {String} template : html template with {{keys}} matching the object
     * @return {String} template : the template string replaced by key:value pairs from the object
     */

    renderTemplate: function(obj, template) {
      let tempKey, reg, key, val;

      if (template === undefined) {
        return "";
      }

      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          tempKey = String("{{" + key + "}}");
          reg = new RegExp(tempKey, "g");
          val =
            key === "0"
              ? obj
              : obj[key] === undefined || obj[key] === null
              ? ""
              : obj[key];
          template = template.replace(reg, val);
        }
      }

      return template;
    },

    /**
     * @description A (possibly faster) way to get the current timestamp as an integer.
     */
    now:
      Date.now ||
      function() {
        return new Date().getTime();
      },

    /**
     * @description Checks if the email string provided is a correct email address
     * @param {Function} email
     */
    validateEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    /**
     * @description Defers a function, scheduling it to run after the current call stack has cleared.
     * @param {Function} func
     */
    defer: function(func) {
      return this.delay.apply(
        null,
        [func, 1].concat([].slice.call(arguments, 1))
      );
    },

    /**
     * @description Delays a function for the given number of milliseconds, and then calls it with the arguments supplied.
     * @param {Function} func
     * @param (Integer) wait : milliseconds
     */
    delay: function(func, wait) {
      var args = [].slice.call(arguments, 2);

      return setTimeout(function() {
        return func.apply(null, args);
      }, wait);
    },

    /**
     * @description Returns a function, that, when invoked, will only be triggered at most once during a given window of time.
     * @param {Function} func
     * @param {Integer} wait : milliseconds
     * @param {Boolean} options.leading : disable the execution on the leading edge. To disable execution on the trailing edge, ditto.
     */
    throttle: function(func, wait, options) {
      var context, args, result;
      var timeout = null;
      var previous = 0;

      options = options || {};

      var later = function() {
        previous = options.leading === false ? 0 : ns.fn.now();
        timeout = null;
        result = func.apply(context, args);
        context = args = null;
      };

      return (function() {
        var now = ns.fn.now();

        if (!previous && options.leading === false) {
          previous = now;
        }

        var remaining = wait - (now - previous);

        context = this;
        args = arguments;

        if (remaining <= 0) {
          clearTimeout(timeout);
          timeout = null;
          previous = now;
          result = func.apply(context, args);
          context = args = null;
        } else if (!timeout && options.trailing !== false) {
          timeout = setTimeout(later, remaining);
        }

        return result;
      })();
    },

    /**
     * @description Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be called after it stops being called for N milliseconds.
     * @param {Function} func
     * @param {Integer} wait : milliseconds
     * @param {Boolean} immediate : if immediate is passed, trigger the function on the leading edge, instead of the trailing.
     */
    debounce: function(func, wait, immediate) {
      var self = this,
        timeout,
        args,
        context,
        timestamp,
        result;

      var later = function() {
        var last = self.now() - timestamp;
        if (last < wait) {
          timeout = setTimeout(later, wait - last);
        } else {
          timeout = null;
          if (!immediate) {
            result = func.apply(context, args);
            context = args = null;
          }
        }
      };

      return (function() {
        context = this;
        args = arguments;
        timestamp = self.now();

        var callNow = immediate && !timeout;
        if (!timeout) {
          timeout = setTimeout(later, wait);
        }
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }

        return result;
      })();
    },

    /**
     * @description delay events with the same id, good for window resize events, keystroke, etc ...
     * @param {Function} func : callback function to be run when done
     * @param {Integer} wait : integer in milliseconds
     * @param {String} id : unique event id
     */
    delayedEvent: (function() {
      var timers = {};

      return function(func, wait, id) {
        wait = wait || 200;
        id = id || "anonymous";

        if (timers[id]) {
          clearTimeout(timers[id]);
        }

        timers[id] = setTimeout(func, wait);
      };
    })(),

    /**
     * @description Equally set height on items that have the same offset top
     * @param {Object} elements : jquery list
     */
    equalHeightTop: function(elements) {
      var el = $(elements),
        len = el.length || 0,
        top = 0,
        first = 0;

      if (len > 1) {
        for (var i = 0; i < len; i++) {
          var off = el.eq(i).offset();
          off.top = Math.ceil(off.top);
          if (off && i === 0) {
            top = off.top;
          }
          if (off && top !== off.top) {
            top = off.top;

            var part = el.slice(first, i);
            first = i;

            this.equalHeight(part);

            // Get offset again after equal height of the previous boxes
            off = el.eq(i).offset();
            top = Math.ceil(off.top);
          }
        }
        this.equalHeight(el.slice(first));
      }
    },

    /**
     * @description Equally set height on items
     * @param {Object} elements : jquery list
     */
    equalHeight: function(elements) {
      var el = $(elements),
        len = el.length || 0,
        highest = 0;

      if (len > 1) {
        while (len--) {
          var h = el.eq(len).outerHeight(true);

          if (h > highest) {
            highest = h;
          }
        }
        // Don't set height when the elements are height 0 (they will probably get shown later)
        if (highest > 5) {
          el.outerHeight(highest);
        }
      }

      return highest;
    },

    /**
     * @description Convert a query alike string to an object literal
     * @param {String} qs : a query string of key value pairs (without ?)
     * @param {String} keyDelimiter : character between values and keys
     * @param {String} valDelimiter : character between keys and values
     * @return {Object} obj : object literal representing the query string
     * @example: key1=val1&key2=val2&key3=val3
     */
    convertQsToLiteral: function(qs, keyDelimiter, valDelimiter) {
      var arrParams,
        obj = {};

      if (qs && qs.length) {
        keyDelimiter = keyDelimiter || cfg.delimiter.key;
        valDelimiter = valDelimiter || cfg.delimiter.val;
        arrParams = qs.split(keyDelimiter);

        $.each(arrParams, function(i, pair) {
          var arrPair = pair.split(valDelimiter),
            key = arrPair[0],
            val = arrPair[1];

          obj[key] = val;
        });
      }

      return obj;
    },

    getUrlParameter: function(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    },

    convertUrlParams: function(paramString) {
      let parameterArray = paramString.split("&");
      let params = {};

      for (let i = 0; i < parameterArray.length; i++) {
        let currentParam = parameterArray[i].split("=");
        params[currentParam[0]] = currentParam[1];
      }

      return params;
    },

    createCookie: function(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },

    readCookie: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return undefined;
    },

    loadScript: function(src, className) {
      if (!this.scriptsqueue) {
        this.scriptsqueue = {};
      }
      if (this.scriptsqueue[className]) {
        return this.scriptsqueue[className];
      }

      let scriptPromise = new Promise(function(resolve, reject) {
        console.debug("Try and load script:");
        console.debug(src);

        let script;
        script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });

      this.scriptsqueue[className] = scriptPromise;
      return scriptPromise;
    },

    loadImageAsync: function(img) {
      img.setAttribute("src", img.getAttribute("data-src"));
      img.setAttribute("data-async-loaded", "");
      img.onload = () => {
        img.removeAttribute("data-src");
      };
    },

    loadBackgroundImageAsync: function(img) {
      img.setAttribute(
        "style",
        "background-image: url(" + img.getAttribute("data-background-src") + ")"
      );
      img.setAttribute("data-async-loaded", "");
      img.removeAttribute("data-background-src");
    },

    escapeRegExp: function(str) {
      return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    },

    replaceAll: function(str, find, replace) {
      return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
    },

    exitFullScreen: function() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },

    getToken: function() {
      return new Promise(function(resolve, reject) {
        $.ajax({
          url: apiTokenUrl,
          success: function(data) {
            var json = JSON.parse(data);
            if (json.jwt) {
              resolve(json);
            }
          },
          dataType: "json"
        });
      });
    }
  };

  String.prototype.replaceAll = function(search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, "g"), replacement);
  };

  // 4. NAMESPACE
  return ns;
})(window.jQuery, window.Syte || {});
